<template>
  <validation-observer ref="formPoint">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>
            {{ $route.meta.breadcrumb[2].text }}
          </h3>
          <b-row class="mt-2">
            <b-col
              md="10"
            >
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="jenis_kfs"
                  >Jenis KFS</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Jenis KFS"
                    rules="required"
                  >
                    <b-form-select
                      id="jenis_kfs"
                      v-model="models.levelId"
                      :options="masters.kfs"
                      class="w-100"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="jumlah_harga_transaksi"
                  >Jumlah Harga Transaksi</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Jumlah harga transaksi"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="jumlah_harga_transaksi"
                      v-model="models.minTransaction"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="jumlah_poin"
                  >Jumlah Poin</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Jumlah point"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="jumlah_poin"
                      v-model="models.point"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="status"
                  >Status</label>
                </b-col>
                <b-col sm="8">
                  <div class="col-sm-12">
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="true"
                        name="status"
                      >
                      <span class="badge badge-success">Aktif</span>
                    </label>
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="false"
                        name="status"
                      >
                      <span class="badge badge-danger">Tidak Aktif</span>
                    </label>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-end">
            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded"
              @click="$router.push({path: config.uri})"
            >
              Batal
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              class="btn-min-width rounded ml-1"
            >
              Simpan
            </b-button>
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/utils/api'
import {
  BCard, BRow, BCol, BFormSelect, BButton, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric } from '@validations'

export default {
  name: 'FormPoint',
  metaInfo: {
    title: 'Form Point',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormSelect,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        levelId: '',
        minTransaction: null,
        point: null,
        status: true,
      },
      masters: {
        kfs: [
          {
            text: '- Pilih Jenis KFS -',
            value: '',
          },
        ],
      },
      required,
      numeric,

    }
  },
  created() {
    this.getById()
    this.getLevel()
  },
  methods: {
    getById() {
      if (this.$route.params.id) {
        this.$axios.get(`${api.point}/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data
            this.models.levelId = data.levelId
            this.models.minTransaction = data.minTransaction
            this.models.point = data.point
            this.models.status = data.status
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },
    getLevel() {
      this.$axios.get(api.level, {
        params: {
          sortBy: 'id.ASC',
          status: true,
        },
      }).then(res => {
        res.data.data.rows.forEach(point => {
          this.masters.kfs.push({
            text: point.name,
            value: point.id,
          })
        })
      })
    },
    save() {
      this.$refs.formPoint.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          if (!this.$route.params.id) {
            request = this.$axios.post(api.point, this.models)
          } else {
            message = 'update'
            request = this.$axios.put(`${api.point}/${this.$route.params.id}`, this.models)
          }
          request
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} point`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push(this.config.uri)
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>

</style>
